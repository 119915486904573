import React from 'react'

import Layout from '../components/layout'
import Explore from '../components/explore'

import thumb1 from '../assets/images/news/thumb-1.jpg'
import thumb2 from '../assets/images/news/thumb-2.jpg'
import thumb3 from '../assets/images/news/thumb-3.jpg'
import thumb4 from '../assets/images/news/thumb-4.jpg'
import thumb5 from '../assets/images/news/thumb-5.jpg'
import thumb6 from '../assets/images/news/thumb-6.jpg'
import thumb7 from '../assets/images/news/thumb-7.jpg'
import thumb8 from '../assets/images/news/thumb-8.jpg'
import thumb9 from '../assets/images/news/thumb-9.jpg'
import thumb10 from '../assets/images/news/thumb-10.jpg'
import thumb11 from '../assets/images/news/thumb-11.jpg'
import thumb12 from '../assets/images/news/thumb-12.jpg'
import thumb13 from '../assets/images/news/thumb-13.jpg'
import thumb14 from '../assets/images/news/thumb-14.jpg'
import thumb15 from '../assets/images/news/thumb-15.jpg'
import thumb16 from '../assets/images/news/thumb-16.jpg'
import thumb17 from '../assets/images/news/thumb-17.jpg'
import thumb18 from '../assets/images/news/thumb-18.jpg'
import thumb19 from '../assets/images/news/thumb-19.jpg'
import thumb20 from '../assets/images/news/thumb-20.jpg'

const NewsItem = ({ link, title, subtitle, date, className, image }) => (
  <div className={className}>
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      <img src={image} className="rounded-md" />
    </a>
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className="group text-main-black block mt-4"
    >
      <div className="text-xs font-inter text-[#6A6A6A]">{date}</div>
      <div className="font-GTWalsheimPro text-lg mt-3 group-hover:text-leg-red group-hover:underline">
        {title}
      </div>
      <div className="text-[#717171] mt-3 font-GTWalsheimPro hidden md:block">
        {subtitle}
      </div>
    </a>
  </div>
)

const News = () => (
  <Layout>
    <div className="default-container mt-[120px]">
      <h3 className="md:hidden font-inter text-[#8A8D99] font-semibold text-xl">NEWS</h3>
      <div className="hidden lg:flex items-center">
        <a
          href="https://lawadvisor.ventures/news/lawadvisor-secures-backing-from-legal-heavyweights-with-seed-round"
          className="flex-4 block mr-4"
          target="_blank"
          rel="noreferrer"
        >
          <img src={thumb1} className="rounded-md" />
        </a>
        <a
          href="https://lawadvisor.ventures/news/lawadvisor-secures-backing-from-legal-heavyweights-with-seed-round"
          className="group flex-3 ml-4"
          target="_blank"
          rel="noreferrer"
        >
          <div className="text-xs font-inter text-[#6A6A6A]">MAY 12, 2022</div>
          <div className="font-GTWalsheimPro text-2xl mt-3 group-hover:text-leg-red group-hover:underline">
            LawAdvisor Secures Backing from Legal Heavyweights with Seed Round
          </div>
          <div className="text-[#717171] mt-3 font-GTWalsheimPro">
            Legaltech specialist’s investors include leading legal and technology innovators from Silicon Valley, including Microsoft and Gilead Sciences. Creates advisory panel to help scale and seize opportunities.
          </div>
        </a>
      </div>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mt-16">
        <NewsItem
          className="lg:hidden"
          link="https://lawadvisor.ventures/news/lawadvisor-secures-backing-from-legal-heavyweights-with-seed-round"
          image={thumb1}
          title="LawAdvisor Secures Backing from Legal Heavyweights with Seed Round"
          subtitle="Legaltech specialist’s investors include leading legal and technology innovators from Silicon Valley, including Microsoft and Gilead Sciences. Creates advisory panel to help scale and seize opportunities."
          date="MAY 12, 2022"
        />
        <NewsItem
          link="https://www.law.com/legaltechnews/2022/05/11/lawadvisor-raises-5-million-investment-with-former-orrick-ceo-silicon-valley-funders-support/"
          image={thumb16}
          title="LawAdvisor Raises $5M Investment, With Former Orrick CEO, Silicon Valley Funders' Support"
          subtitle="LawAdvisor, which released two products in 2021 designed with input from Barclays and a host of law firms, will bring former Orrick CEO and chairman Ralph Baxter and other investors on as advisers."
          date="MAY 12, 2022"
        />
        <NewsItem
          link="https://www.businessnewsaustralia.com/articles/lawadvisor-secures--7m-in-backing-after-raising-the-bar-with-new-technology.html"
          image={thumb15}
          title="Melbourne-founded LawAdvisor secures $7m in backing after raising the bar with new technology"
          subtitle="Melbourne-founded Legal-tech startup LawAdvisor Ventures has raised $7.18 million from backers, including Google Maps founder Lars Rasmussen..."
          date="MAY 12, 2022"
        />
        <NewsItem
          link="https://www.verdict.co.uk/google-for-law-startup-lawadvisor-scores-5m-investment"
          image={thumb18}
          title="“Google for law” startup LawAdvisor scores $5m investment"
          subtitle="LawAdvisor is the latest startup aiming to revolutionise legal practices, and with $5m new investment underneath its belt, it has just strengthened its ability to smash efficiency roadblocks for lawyers."
          date="MAY 12, 2022"
        />
        <NewsItem
          link="https://legaltechnology.com/2022/05/12/lawadvisor-raises-5m-seed-funding-from-heavyweight-investors/"
          image={thumb17}
          title="LawAdvisor raises $5m seed funding from heavyweight investors"
          subtitle="Melbourne-founded legaltech startup LawAdvisor Ventures has raised USD$5m of investment in a seed round involving heavyweight investors from the likes of Microsoft and Gilead Sciences, as well as Google Maps founder Lars Rasmussen."
          date="MAY 12, 2022"
        />
        <NewsItem
          link="https://www.law360.com/pulse/amp/articles/1492636"
          image={thumb20}
          title="Ex-Orrick CEO Joins Seed Round Funding For Legal Tech Co."
          subtitle="A group of legal industry giants, including the former chairman and CEO of Orrick Herrington & Sutcliffe LLP, has backed legal technology software company LawAdvisor Ventures in the form of a..."
          date="MAY 12, 2022"
        />
        <NewsItem
          link="https://www.legalfutures.co.uk/latest-news/lawtech-start-ups-bag-multi-million-pound-investments'"
          image={thumb14}
          title="Lawtech start-ups bag multi-million pound investments"
          subtitle="LawAdvisor Ventures, a lawtech start-up with software platforms aimed at corporate in-house legal teams has raised $5m (£4m) from big-name Silicon Valley backers associated with Google, Facebook and Microsoft."
          date="MAY 12, 2022"
        />
        <NewsItem
          link="https://www.thelawyermag.com/au/news/general/big-investors-inject-us5m-into-melbourne-born-legaltech-startup/405702"
          image={thumb12}
          title="Big investors inject US$5m into Melbourne-born legaltech startup"
          subtitle="A group of major investors have invested US$5m into Melbourne-founded legaltech company LawAdvisor Ventures in a seed round."
          date="MAY 12, 2022"
        />
        <NewsItem
          link="https://www.enterprisetimes.co.uk/2022/05/12/is-this-the-start-of-something-big-in-lawtech/"
          image={thumb13}
          title="Is this the start of something big in Lawtech"
          subtitle="London headquartered Lawtech firm LawAdvisor Ventures has raised US$5 million in a seed round led by some significant industry angel investors. The funding will help the company scale and further accelerate development in its product portfolio, helping to change how law is practised."
          date="MAY 12, 2022"
        />
        <NewsItem
          link="https://techfundingnews.com/google-map-founder-backs-lawadvisor-in-5m-round-to-transform-legal-service-delivery/"
          image={thumb19}
          title="Google Map founder backs LawAdvisor in $5M round to transform legal service delivery"
          subtitle="LawAdvisor Venture, a London-based legal technology platform, announced on Thursday that it has secured $5M funding in the Seed round from Ralph Baxter, Ben Davey, Jason Barnwell, Gary Tully, and Lars Rasmussen, founder of Google Map."
          date="MAY 12, 2022"
        />
        <NewsItem
          link="https://lawadvisor.ventures/news/lawadvisor-launches-new-solutions-in-collaboration-with-barclays"
          image={thumb11}
          title="LawAdvisor launches new solutions in collaboration with Barclays and leading law firms to revolutionise the practice of law"
          subtitle="Today legal technology specialist LawAdvisor, has launched two innovative new legal technology solutions, aimed at transforming the way law firms work with corporate in-house legal departments and legal matters are managed."
          date="JANUARY 26, 2021"
        />
        <NewsItem
          link="https://www.law360.com/banking/articles/1348564/amid-digital-legal-changes-lawadvisor-launchs-tech-tools-"
          image={thumb10}
          title="Amid Digital Legal Changes, LawAdvisor Launchs Tech Tools"
          subtitle="As the growing intersection between law and technology continues to take hold in the legal industry, legal technology company LawAdvisor has launched two new products..."
          date="JANUARY 27, 2021"
        />
        <NewsItem
          link="https://www.law.com/international-edition/2021/01/27/barclays-adds-panel-law-firms-to-new-collaborative-platform/"
          image={thumb9}
          title="Barclays Adds Panel Law Firms to New Collaborative Platform"
          subtitle="The new tech solution is one of two the bank created with LawAdvisor and firms including Allen & Overy and Paul Hastings."
          date="JANUARY 27, 2021"
        />
        <NewsItem
          link="https://www.reuters.com/article/legaltech-lawadvisor/lawadvisor-partners-with-barclays-law-firms-on-new-legal-ops-tools-idUSL1N2K10QN"
          image={thumb8}
          title="LawAdvisor partners with Barclays, law firms, on new legal ops tools"
          subtitle="Legal tech company LawAdvisor is unveiling two new products for corporate legal departments and law firms with the help of Barclays plc and several law firms, including Bird & Bird."
          date="JANUARY 26, 2021"
        />
        <NewsItem
          link="https://www.legalfutures.co.uk/latest-news/competition-hots-up-to-become-standard-software-for-in-house-teams"
          image={thumb7}
          title="Competition hots up to become standard software for in-house teams"
          subtitle="A software platform aimed at bringing together the various apps used by corporate legal teams is launched today."
          date="JANUARY 26, 2021"
        />
        <NewsItem
          link="https://roboticslawjournal.com/news/aussie-legaltech-specialist-lawadvisor-looks-to-grow-its-global-market-share-with-london-move-47139282"
          image={thumb2}
          title="Aussie legaltech specialist LawAdvisor looks to grow its global market share with London move"
          subtitle="The startup, which is backed by a co-founder of Google Maps, has moved its headquarters to the British capital to position itself at the centre of the global legaltech industry..."
          date="NOVEMBER 26, 2020"
        />
        <NewsItem
          link="https://www.afr.com/technology/google-maps-cofounder-lars-rasmussen-backs-australian-startup-lawadvisor-20160819-gqx3bq"
          image={thumb3}
          title="Google Maps co-founder Lars Rasmussen backs Australian start-up LawAdvisor"
          subtitle="Google Maps co-founder and former Facebook executive Lars Rasmussen has invested in Australian legal tech start-up..."
          date="AUGUST 23, 2016"
        />
        <NewsItem
          link="https://www.globallegalpost.com/news/lawadvisor-in-start45up-acquisition-70630780"
          image={thumb4}
          title="LawAdvisor invests in legal automation, acquires early-stage startup Kobi.ai from Mills Oakley"
          subtitle="Melbourne-founded legal management software-as-a-service startup LawAdvisor has..."
          date="JULY 19, 2019"
        />
        <NewsItem
          link="https://legaltechnology.com/2017/09/06/lonely-planet-legal-adopts-lawadvisor-corporate-for-managing-outside-counsel/"
          image={thumb5}
          title="Lonely Planet legal adopts LawAdvisor Corporate for managing outside counsel"
          subtitle="Lonely Planet has helped to develop and now adopted an outside counsel procurement and management platform from Melbourne-founded legal tech startup LawAdvisor, which is backed by Janders Dean and launched in the UK this year."
          date="SEPTEMBER 6, 2017"
        />
        <NewsItem
          link="https://www.afr.com/companies/professional-services/lawyers-as-tecopa-pupfish-can-they-avoid-extinction-20150710-gi9iix"
          image={thumb6}
          title="Want legal advice? Don&apos;t ask Siri: upstart web service opens law access"
          subtitle="Lawyers are scary. A new tech start-up wants to change that."
          date="JULY 17, 2015"
        />
      </div>
    </div>
    <Explore />
  </Layout>
)

export default News
